import * as Sentry from '@sentry/vue'

export function applySentry(app, router) {
  Sentry.init({
    app,
    dsn: 'https://b5294689438f6956e7ebc90b96d4d48e@o4507752806875136.ingest.de.sentry.io/4507752811987024',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export function logError(message) {
  Sentry.captureMessage(message, {
    level: 'error'
  })
}
