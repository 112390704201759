export const GameState = Object.freeze({
  STARTING: 'starting',
  QR: 'qr',
  SHOPPING: 'shopping',
  QUIZ_SELECTION: 'quiz-selection',
  QUIZ_QUESTION: 'quiz-question',
  QUIZ_ANSWER: 'quiz-answer',
  QUIZ_PURCHASE: 'quiz-purchase',
  FINISHED: 'finished'
})

export function getTitle(state) {
  switch (state) {
    case GameState.STARTING:
      return 'Starting'
    case GameState.QR:
      return 'QR Codes'
    case GameState.SHOPPING:
      return 'Shopping'
    case GameState.QUIZ_SELECTION:
      return 'Quiz - Selection'
    case GameState.QUIZ_QUESTION:
      return 'Quiz - Question'
    case GameState.QUIZ_ANSWER:
      return 'Quiz - Answer'
    case GameState.QUIZ_PURCHASE:
      return 'Quiz - Purchase'
    case GameState.FINISHED:
      return 'Finished'
  }
}
