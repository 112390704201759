import { defineStore } from 'pinia'

import { ref } from 'vue'

export const useLocalSettingsStore = defineStore(
  'user_settings',
  () => {
    const darkModeClass = 'app-dark-mode'
    const isDarkMode = ref(true)
    const loginEmail = ref('')

    function applyDarkMode() {
      const element = document.querySelector('html')
      if (isDarkMode.value) {
        element.classList.add(darkModeClass)
      } else {
        element.classList.remove(darkModeClass)
      }
    }

    function toggleDarkMode() {
      isDarkMode.value = !isDarkMode.value
      applyDarkMode()
    }

    return { isDarkMode, applyDarkMode, toggleDarkMode, loginEmail }
  },
  {
    persist: true
  }
)
