import { createApp } from 'vue'

import '@/assets/main.css'
import router from '@/router/index.js'
import App from '@/app/App.vue'
import { applyPrimeVue } from '@/app/primevue.js'
import { pinia } from '@/app/pinia.js'
import { applySentry } from '@/app/sentry.js'

const app = createApp(App)

applySentry(app, router)
app.use(pinia)
app.use(router)
applyPrimeVue(app)

app.mount('#app')
