import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import { supabase } from '@/lib/supabaseClient.js'
import { GameState } from '@/utils/gameState.js'
import { QuizProgressStatus } from '@/utils/quizProgressStatus.js'
import { logError } from '@/app/sentry.js'

export const useGameStore = defineStore('game', () => {
  const loggedInUser = ref(null)
  const isHost = computed(() => loggedInUser.value != null && !loggedInUser.value['is_anonymous'])
  const isPlayer = computed(() => teamName.value)
  const state = ref('')
  const pin = ref(0)
  const game_id = ref(0)
  const active_team = ref('')
  const teamName = ref('')
  const initialState = GameState.STARTING

  const isActiveTeam = computed(() => {
    return teamName.value === active_team.value
  })

  async function clearCurrentlySelectedQuestion() {
    const { error } = await supabase
      .from('quiz_progress')
      .update({ status: QuizProgressStatus.INITIAL })
      .eq('status', QuizProgressStatus.CURRENT)
    if (error) {
      throw error
    }
  }

  async function setState(newState) {
    const { error } = await supabase.from('games').update({ state: newState }).eq('pin', pin.value)
    if (error) {
      throw error
    }
    if (newState === GameState.QUIZ_SELECTION) {
      await clearCurrentlySelectedQuestion()
    }
  }

  async function toggleActiveTeam() {
    const newTeam = active_team.value === 'A' ? 'B' : 'A'
    const { error } = await supabase
      .from('games')
      .update({ active_team: newTeam })
      .eq('pin', pin.value)
    if (error) {
      throw error
    }
  }

  function getRandomPin() {
    const min = 1000000
    const max = 9999999
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  async function joinExisting() {
    // Look for an active game
    const { data, error } = await supabase.from('games').select().eq('is_active', true)
    if (error) {
      throw error
    }
    if (data && data.length > 0) {
      // Join existing game
      if (data.length > 1) {
        logError(`Expecting only one active game. Found ${data.length}`)
      }
      state.value = data[0]['state']
      pin.value = data[0]['pin']
      game_id.value = data[0]['id']
      active_team.value = data[0]['active_team']
      return true
    }
    return false
  }

  async function initialiseShoppingLists(game_id) {
    const { error } = await supabase.rpc('initialise_shopping_lists', { game_id: game_id })
    if (error) {
      throw error
    }
  }

  async function initialiseQuizProgress(game_id) {
    const { error } = await supabase.rpc('initialise_quiz_progress', { game_id: game_id })
    if (error) {
      throw error
    }
  }

  async function createNewGame() {
    // Create new game
    const newPin = getRandomPin()
    const { data, error } = await supabase
      .from('games')
      .insert({ state: initialState, pin: newPin, is_active: true, active_team: 'A' })
      .select()
    if (error) {
      throw error
    }
    const new_game_id = data[0]['id']
    await initialiseShoppingLists(new_game_id)
    await initialiseQuizProgress(new_game_id)

    // Declare the game started
    state.value = initialState
    pin.value = newPin
    game_id.value = new_game_id
  }

  async function joinExistingOrStartNew() {
    const existing = await joinExisting()
    if (!existing) {
      await createNewGame()
    }
  }

  return {
    isHost,
    isPlayer,
    loggedInUser,
    state,
    pin,
    game_id,
    teamName,
    active_team,
    isActiveTeam,
    toggleActiveTeam,
    setState,
    joinExisting,
    joinExistingOrStartNew
  }
})
