<script setup>
import { onMounted, onUnmounted } from 'vue'

import { supabase } from '@/lib/supabaseClient.js'

import { useGameStore } from '@/stores/game.js'

const gameStore = useGameStore()

function watchGame() {
  // Watch for a new game or updates to current game
  supabase
    .channel('games')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'games'
      },
      (payload) => {
        if (payload['new']['is_active']) {
          gameStore.state = payload['new']['state']
          gameStore.pin = payload['new']['pin']
          gameStore.active_team = payload['new']['active_team']
        }
      }
    )
    .subscribe()
}

onMounted(() => {
  gameStore.joinExisting()
  watchGame()
})

onUnmounted(() => {
  supabase.removeAllChannels()
})
</script>
<template>
  <div />
</template>
