<script setup>
import { onMounted, onErrorCaptured } from 'vue'

import * as Sentry from '@sentry/vue'

import RealTimeMonitor from '@/components/RealTimeMonitor.vue'
import TopNavigationBar from '@/components/TopNavigationBar.vue'
import router from '@/router/index.js'
import { useLocalSettingsStore } from '@/stores/localSettings.js'

const localSettingStore = useLocalSettingsStore()

onMounted(() => {
  localSettingStore.applyDarkMode()
})

onErrorCaptured((err) => {
  console.log(err)
  Sentry.captureException(err)
  router.push('/generic_error')
  return false
})
</script>

<template>
  <RealTimeMonitor />
  <div class="flex flex-col w-full h-full min-h-screen dark:bg-neutral-900">
    <header>
      <TopNavigationBar />
    </header>
    <main>
      <RouterView />
    </main>
  </div>
</template>
