import { createRouter, createWebHistory } from 'vue-router'

const GenericError = () => import('@/views/generic/GenericError.vue')
const NotFound = () => import('@/views/generic/NotFound.vue')

const HomeView = () => import('@/views/HomeView.vue')
const PresentationView = () => import('@/views/presentation/PresentationView.vue')
const LoginView = () => import('@/views/host/LoginView.vue')
const HostRemoteView = () => import('@/views/host/HostRemoteView.vue')
const PlayerView = () => import('@/views/player/PlayerView.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', component: HomeView },
    { path: '/presentation', name: 'presentation', component: PresentationView },
    { path: '/login', name: 'login', component: LoginView },
    { path: '/host_remote', name: 'host_remote', component: HostRemoteView },
    { path: '/game/:pin/team/:team_name', name: 'player', component: PlayerView },
    { path: '/generic_error', name: 'generic_error', component: GenericError },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
  ]
})

export default router
